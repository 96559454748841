import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import { lazy, Suspense} from 'react';
const Callback = lazy(() => import('./components/callback'))
const Auth = lazy(() => import('./components/auth'))

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route
              key="index"
              path="/"
              exact={true}
              element={
                <Suspense fallback={<></>}>
                  <div>Hey from Central Auth</div>
                </Suspense>
              }
            />
            <Route
              key="callback"
              path="/auth/callback"
              exact={false}
              element={
                <Suspense fallback={<></>}>
                  <Callback/>
                </Suspense>
              }
            />
            <Route
              key="auth"
              path="/auth"
              exact={false}
              element={
                <Suspense fallback={<></>}>
                  <Auth/>
                </Suspense>
              }
            />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
